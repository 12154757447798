import React from 'react';
import { Box, Button } from '@urbaninfrastructure/react-ui-kit';
import { useSystem } from '../lib/core/useSystem';
import { FormattedMessage } from 'react-intl';

function launchApp(systemId: string) {
  let url = '';
  switch (systemId) {
    case 'oslobysykkel':
      url = 'oslobysykkel://stations';
      break;
    case 'trondheim':
      url = 'trondheimbysykkel://stations';
      break;
    case 'bikemi':
      url = 'bikemi://stations';
      break;
    case 'bergen-city-bike':
      url = 'bergenbysykkel://stations';
      break;
    default:
      url = '';
      break;
  }
  window.location.replace(url);
}

export const LaunchAppButton = () => {
  const system = useSystem();
  const systemId = system.id;

  if (!systemId) {
    return null;
  }

  return (
    <Box mb="xs">
      <Button shape="cool" onClick={() => launchApp(systemId)}>
        <FormattedMessage id="launch_app" defaultMessage="Launch app" />
      </Button>
    </Box>
  );
};
